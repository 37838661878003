
























































import Vue from 'vue';
import { IContactDataservice } from '../../../shared/src/dataservices/IContactDataservice';
import TextField from '../components/TextField.vue';
import TextArea from '../components/TextArea.vue';
import ImageUpload from '../components/ImageUpload.vue';

export default Vue.extend({
  components: {
    TextField,
    ImageUpload,
    TextArea
  },
  inject: {
    contactDataservice: 'contactDataservice',
    uuidHelperService: 'uuidHelperService'
  },
  methods: {
      contactTextUpdated(contactText: string): void {
        ((this as any).contactDataservice as IContactDataservice).contactText = contactText;
      },
      facebookLinkUpdated(facebookLink: string): void {
        ((this as any).contactDataservice as IContactDataservice).facebookLink = facebookLink;
      },
      instagramLinkUpdated(instagramLink: string): void {
        ((this as any).contactDataservice as IContactDataservice).instagramLink = instagramLink;
      },
      emailUpdated(email: string): void {
        ((this as any).contactDataservice as IContactDataservice).email = email;
      },
      imageUpdated(image: File): void {
        if (!image) {
          throw new Error('Trying to updated undefined image');
        }

        // TODO this.imageLoading = true;
        ((this as any).contactDataservice as IContactDataservice).setContactImage(image).then(() => {
          // TODO this.imageLoading = false;
        });
      },
      imageDeleted(): void {
        ((this as any).contactDataservice as IContactDataservice).deleteContactImage();
      },
      contactPageActiveUpdated(contactPageActive: boolean): void {
        ((this as any).contactDataservice as IContactDataservice).contactPageActive = contactPageActive;
      }
  }
});
